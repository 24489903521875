.banner {
    height: 200px;
    margin: 0 auto;
    background-image: url('@images/index/banner.png');
}

.con_r{
    margin-left: 40px;
    padding-bottom: 80px;
    min-height: 680px;
}

.item{
    width: 100%;
    height: 44px;
    color: #000;
    border-bottom: 1px dashed #eaeaea;
    cursor: pointer;
}
.time{
    color: #808080;
    margin-left: 20px;
}

.item:hover,
.item:hover .time{
    color: #05a4ff;
}

