.modal_l{
    width: 290px;
    padding: 20px;
    height: 500px;
    background-color: #f6fbff;

}
.modal_l_label{
    width: 80px;
    padding-right: 10px;
    text-align: right;
}

.modal_r{
    overflow: hidden;
    padding: 20px 40px;
}

.line{
    margin: 20px;
    text-align: center;
    color: #909090;
    font-size: 12px;
}
.line_con{
    margin: 0 10px;
}
.line_l,
.line_r{
    border-top: 1px dashed #909090;
}

.text-label{
    text-align: right;
}
.text-name{
    padding-left: 4px;
}

.coll_label{
    padding-right: 10px;
    text-align: right;
}
.coll_b{
    margin-top: 40px;
    padding-top: 16px;
    border-top: 1px dashed #909090;
}