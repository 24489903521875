
.top{
    height: 100px;
    background-color: #fff;
}
.top_con{
    position: relative;
    width: 1180px;
    height: 100%;
    margin: 0 auto;
}
.top_con img{
    height: 100%;
    width: auto;
}

.top_con:before,
.top_con:after{
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
}
.top_con:before{
    width: 365px;
    right: 100%;
    background-image: url('@images/top1.png')
}
.top_con:after{
    width: 566px;
    right: 0;
    background-image: url('@images/top2.png')
}

.list{
    position: relative;
    height: 40px;
    line-height: 40px;
    color: #fff;
    background-color: #05a4ff;
}

.list:before,
.list:after{
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    border-top: 1px solid #fff;
}

.list:before{
    top: 2px
}

.list:after{
    bottom: 2px
}

.item{
    display: inline-block;
    width: 120px;
    text-align: center;
    cursor: pointer;
    vertical-align: top;
}

.item:hover{
    background-color: rgba(255, 255, 255, 0.2)
}

.banner {
    height: 380px;
    margin: 0 auto;
}