.qaa{
    padding-bottom: 40px;
}
.bread{
    background-color: #f9f9f9

}
.con{
    min-height: 500px;
}
.text-sub{
    color: #8c8c8c;
    font-size: 14px;
}
.label{
    margin-right: 15px;
}
.search_box :global(.ant-input){
    border-radius: 16px;
}
.tab_list .tab_item{
    display: inline-block;
    vertical-align: top;
    padding: 0px 10px;
    margin-bottom: 5px;
    color: #000;
    cursor: pointer;
}
.tab_list .tab_item:hover{
    color: #05a4ff
}

.list .item{
    padding: 20px 0;
    border-bottom: 1px solid #e8e8e8;
}

.wt{
    text-align: justify;
    color: #333;
    font-weight: bold;
    font-size: 18px;
}

.time{
    margin-left: 15px;
}
.time_2{
    margin-left: 0;
}
.nr{
    padding: 10px 0;
    font-size: 14px;
    color: #666;
}
.zan{
    vertical-align: top;
    cursor: pointer;
}
.page_box{
    padding: 20px 0;
    color: #000;
}
.tw_btn{
    color: #05a4ff;
    font-weight: bold;
    border-bottom: 1px solid #05a4ff;
    cursor: pointer;
}
.tw_box{
    border: 1px solid #e8e8e8;
    min-height: 400px;
}

.tw_box_top{
    background-color: #fdfdfd;
    padding: 10px;
    color: #000;
    border-bottom: 1px solid #e8e8e8;
}

.tw_box_top :global(.anticon-close){
    cursor: pointer;
}

.empty{
    margin-top: 20px;
    padding: 80px;
    border: 1px dashed #e8e8e8;
    border-radius: 6px;
    background-color: #fdfdfd;
    text-align: center;
    color: #8c8c8c;
}