.body {
    display: flex;
    width: 80%;
    margin: 10px auto;
    flex-direction: row;
  }
  .body .left_nav {
    margin: 0px 10px;
    width: 224px;
    height: 354px;
    position: relative;
    display: flex;
    flex-direction: column;
  }
  .body .left_nav .head {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 54px;
    background-color: #05a4ff;
    /* background: url("../../../assets/images/column/left-nav.png"); */
  }
  .body .left_nav .head .head_title {
    width: 72px;
    height: 18px;
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    line-height: 32px;
    color: #FFFFFF;
    opacity: 1;
    margin: 11px 20px !important;
  }
  .body .left_nav .content {
    position: absolute;
    top: 54px;
    left: 0;
    flex-grow: 1;
    /* background: url("../../../assets/images/column/left-content.png"); */
    width: 100%;
    height: calc(100% - 100px);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 100%;
    background-color: #fafafa;
    background-repeat: no-repeat;
    background-position-y: bottom;
    background-repeat: no-repeat;
  }
  .body .left_nav .content ul {
    list-style: none;
  }
  .body .left_nav .content ul li {
    margin: 20px 30px;
    height: 16px;
    font-size: 16px;
    font-family: SourceHanSansCN-Regular;
    line-height: 25px;
    opacity: 1;
  }
  .body .right_content {
    flex-grow: 1;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
  }
  .body .right_content .boxTitle {
    border-bottom: 2px solid #ddd;
    margin-bottom: 10px;
  }
  .body .right_content .boxTitle a {
    display: inline-block;
    position: relative;
    margin-bottom: -1px;
    border-bottom: 4px solid #113273;
    font-size: 28px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    line-height: 37px;
    color: #113273;
    opacity: 1;
    padding-bottom: 9px;
  }
  .body .right_content .right_tip {
    width: 100%;
    height: 21px;
    margin-bottom: 10px;
  }
  .body .right_content .right_tip span {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    line-height: 21px;
    color: #5A5A5A;
    opacity: 1;
  }
  .body .right_content .video_display {
    margin: 0 auto;
    margin-bottom: 20px;
    flex-grow: 1;
    overflow: hidden;
    width: 850px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    gap: 20px;
  }
  .body .right_content .video_display .video_display_div {
    width: 270px;
    height: 186.49px;
    background-color: #05a4ff;
    position: relative;
  }
  .body .right_content .video_display .video_display_div:hover {
    box-shadow: 5px 5px 5px 0px #636363;
  }
  .body .right_content .video_display .video_display_div:hover img {
    transform: scale(1.008);
    z-index: 10;
  }
  .body .right_content .video_display .video_display_div .video_title {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 40px;
    width: 100%;
    text-align: center;
    height: 24px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 24px;
    color: #FFFFFF;
    background: rgba(0, 0, 0, 0.65);
    opacity: 1;
  }
  .body .right_content .right_pagination {
    width: 100%;
    height: 19.4px;
    display: flex;
    flex-direction: row;
    margin-bottom: 57px;
  }
  .body .right_content .right_pagination .right_pageTip {
    width: 440px;
    height: 19px;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    line-height: 25px;
    color: #000000;
    opacity: 0.65;
  }
  .body .right_content .right_pagination .right_pagenation_page {
    height: 100%;
    flex-grow: 1;
    float: right;
    text-align: right;
  }
  
  .video_explain {
    text-align: center;
    height: 30px;
    font-size: 18px;
    font-family: MicrosoftYaHei;
    color: #000000;
  }

  .playWarp {
    width: 56px;
    height: 55px;
    display: inline-block;
    background: #000000;
    border-radius: 50%;
    opacity: 0.63;
    position: absolute;
    top: 49%;
    right: 27%;

    transform: translate(-50%, -50%);
    padding-left: 22px;
    padding-top: 14px;
    transition: all 0.5s linear;

  }
  .playIcon {
    display: inline-block;
    width: 0;
    height: 0;
    border-top: 13px solid transparent;
    border-left: 19px solid rgba(255, 255, 255, 0.62);
    border-bottom: 13px solid transparent;
    border-radius: 10% 10% 10% 10%;
    border-top-left-radius: 10%;
    border-top-right-radius: 10%;
    border-bottom-right-radius: 10%;
    border-bottom-left-radius: 10%;
    cursor: pointer;
  }