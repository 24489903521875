.banner {
    height: 380px;
    margin: 0 auto;
}
.box_top {
    height: 40px;
    line-height: 40px;
    color: #c0c0c0;
    font-size: 14px;
    border-bottom: 1px solid #d1d1d1;
}

.box_top_l,
.box_top_r {
    display: inline-block;
    vertical-align: top;
}
.box_top_l {
    width: 93px;
    color: #333;
    font-weight: bold;
    font-size: 16px;
    height: 41px;
    border-bottom: 3px solid #05a4ff;
}

.line {
    display: inline-block;
    margin: 12px 5px 12px 0;
    height: 16px;
    border-right: 1px solid #bfbfbf;
}

.box_top_l img {
    height: 20px;
    width: 20px;
    vertical-align: top;
    margin: 10px 2px 10px 0;
}

.grxx {
    width: 345px;
    margin-right: 20px;
}

.grxx_con {
    height: 205px;
    padding: 20px 0;
}

.grxx_con_l {
    display: inline-block;
    width: 112px;
    height: 165px;
    vertical-align: top;
}
.grxx_con_r {
    display: inline-block;
    vertical-align: top;
    height: 165px;
    width: 213px;
    margin-left: 20px;
}

.grxx_con_item {
    color: #010101;
    line-height: 26px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: justify;
}

/*.grxx_con_item + .grxx_con_item {
    margin-top: 16px;
}*/

.tzgg {
    width: 815px;
}
.tzgg_list {
    padding: 20px 0;
}

.tzgg_item {
    position: relative;
    width: 815px;
    overflow: hidden;
    color: #010101;
    cursor: pointer;
}
.tzgg_item + .tzgg_item {
    margin-top: 16px;
}

.tzgg_line {
    border-top: 1px dashed #010101;
    margin-left: 10px;
}

.tzgg_item:hover {
    color: #05a4ff;
}
.tzgg_item:hover .tzgg_line {
    border-top: 1px dashed #05a4ff;
}

.tzgg_item_icon {
    display: inline-block;
    margin-right: 10px;
    height: 14px;
    width: 14px;
    border-radius: 50%;
    background-color: #f7a837;
    color: #fff;
    line-height: 1;
    font-size: 12px;
    text-align: center;
}

.bdlc_con {
    display: flex;
    padding: 30px 0;
}
/* .bdlc_item + .bdlc_item {
    margin-left: 15px;
} */
.bdlc_item {
    width:168px;
    padding: 0 10px
    }
.bdlc_item_img {
    text-align: center;
}
.bdlc_item_num {
    display: inline-block;
    height: 24px;
    width: 24px;
    background-color: #05a4ff;
    color: #fff;
    line-height: 24px;
    text-align: center;
    margin-right: 5px;
    border-radius: 50%;
}

.bdlc_item:nth-of-type(4n + 2) .bdlc_item_num {
    background-color: #69d398;
}

.bdlc_item:nth-of-type(4n + 3) .bdlc_item_num {
    background-color: #f7a837;
}
.bdlc_item:nth-of-type(4n + 4) .bdlc_item_num {
    background-color: #f87a58;
}

.bdlc_item_title {
    color: #333;
    font-weight: bold;
}
.bdlc_item_sub {
    height: 35px;
    padding-left: 29px;
    /* text-align: justify; */
    font-size: 12px;
    color: #000;
}

.bdlc_item img {
    display: inline-block;
    width: 110px;
    height: 84px;
    /* margin: 15px auto 0; */
    margin-left:15px;

}

.last {
    padding-bottom: 40px;
    color: #040404;
}
.ksdh {
    width: 255px;

}
.ksdh_con {
    padding: 20px 0;
}

.ksdh_con :global(.ant-select) + :global(.ant-select) {
    margin-top: 10px;
}

.ksdh_con :global(.ant-select-selection__placeholder) {
    text-align: center;
}
.cjyy {
    width: 540px;
    margin-right: 20px;
}
.ksdh_con {
    padding: 20px 0;
}
.ksdh_item {
    text-align: center;
    cursor: pointer;
}
.ksdh_item:hover {
    color: #05a4ff;
}
.ksdh_con img {
    width: 82px;
    height: 82px;
}
.ksdh_con p {
    text-align: center;
    margin-top: 15px;
}

.ksdh_select{
    width:255px;
}

.lxdh {
    width: 345px;
    margin-right: 20px;
}

.lxdh_con {
    padding: 20px 0;
    padding-bottom: 0;
    height:165px;
    overflow-y: auto;
}


.lxdh_con div + div {
    margin-top: 10px;
}

.ant-carousel .slick-slide {
    text-align: center;
    overflow: hidden;
}

.ant-carousel .slick-slide h3 {
    color: #fff;
}