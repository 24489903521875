.title_top{
    padding-right: 20px; 
}
.title_top_name{
    padding-left: 16px;
    border-left: 4px solid #05a4ff;
    height: 24px;
    font-size: 18px;
    color: #333;
    font-weight: bold;
}
.title_top :global(.ant-btn){
    height: 26px;
    border-radius: 13px;
}
.line{
    margin-left: 10px;
    border-top: 1px solid #eaeeed;
}