/* 统一pc端移动端承诺书样式 */
.signature ol,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
p,
th,
td,
dl,
dd,
form,
fieldset,
legend,
input,
textarea,
select {
  margin: initial;
  padding: initial;
  font-size: initial;
}

.signature p {
  margin-bottom: 16px;
  /* 不转换位rem，大写px */
}

.signature img {
  vertical-align: middle;
  max-width: 100%;
}

.toSign {
  padding: 24px;
  font-size: 20px;
  text-align: center;
}
