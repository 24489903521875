html,
body {
    min-width: 1180px;
}

.w1180 {
    width: 1180px;
    margin: 0 auto;
}

._bg {
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #f2f2f2;
}

.justify {
    text-align: justify;
}
.t-clamp-1 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-align: justify;
}
.t-clamp-2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-align: justify;
}
.t-clamp-3 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-align: justify;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-bold {
    font-weight: bold;
}

/* 颜色 */
.text-red,
.text-danger {
    color: red;
}

.text-info-red {
    color: #fe5d7f;
}

.text-primary {
    color: #0273fd;
}
.text-blue {
    color: #04a5ff;
}
.text-success {
    color: #6ac566;
}

.text-orange {
    color: #fdc7a3;
}

.text-title{
    color: #000
}

.text-desc {
    color: #666666;
}

.text-sub {
    color: #999999;
}

/* 字体 */

.font-12 {
    font-size: 12px !important;
}

.font-14 {
    font-size: 14px !important;
}

.font-16 {
    font-size: 16px !important;
}

/*  边距 */

.p-w {
    padding: 0 15px;
}

.p-sw {
    padding: 20px;
}
.p-swc {
    padding: 0px 20px 20px 20px;
}

.p-sw-big{
    padding: 20px 40px;
}

.mt-100 {
    margin-top: 100px;
}

.mt-50 {
    margin-top: 50px;
}

.mt-30 {
    margin-top: 30px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-15 {
    margin-top: 15px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-5 {
    margin-top: 5px;
}

.mt-0 {
    margin-top: 0;
}

.ml-5 {
    margin-left: 5px;
}

.ml-10 {
    margin-left: 10px;
}

.ml-20 {
    margin-left: 20px;
}

.mr-10 {
    margin-right: 10px;
}

.mr-20 {
    margin-right: 20px;
}

/* flex */
.c-flex {
    text-align: left;
    overflow: hidden;
    display: flex;
    align-items: center;
}
.c-flex-dir-row {
    flex-direction: row;
}
.c-flex-dir-row-reverse {
    flex-direction: row-reverse;
}
.c-flex-dir-column {
    flex-direction: column;
}
.c-flex-dir-column-reverse {
    flex-direction: column-reverse;
}
.c-flex-nowrap {
    flex-wrap: nowrap;
}
.c-flex-wrap {
    flex-wrap: wrap;
}
.c-flex-wrap-reverse {
    flex-wrap: wrap-reverse;
}
.c-flex-justify-start {
    justify-content: flex-start;
}
.c-flex-justify-end {
    justify-content: flex-end;
}
.c-flex-justify-center {
    justify-content: center;
}
.c-flex-justify-between {
    justify-content: space-between;
}
.c-flex-justify-around {
    justify-content: space-around;
}
.c-flex-align-start {
    align-items: flex-start;
}
.c-flex-align-end {
    align-items: flex-end;
}
.c-flex-align-center {
    align-items: center;
}
.c-flex-align-stretch {
    align-items: stretch;
}
.c-flex-align-baseline {
    align-items: baseline;
}
.c-flex-align-content-start {
    align-content: flex-start;
}
.c-flex-align-content-end {
    align-items: flex-end;
}
.c-flex-align-content-center {
    align-items: center;
}
.c-flex-align-content-between {
    align-items: stretch;
}
.c-flex-align-content-around {
    align-items: baseline;
}
.c-flex-align-content-stretch {
    align-items: baseline;
}
.c-flex-item {
    box-sizing: border-box;
    flex: 1;
}

.ant-layout {
    background-color: #ffffff;
}
.ant-breadcrumb {
    display: inline-block;
}
.ant-breadcrumb a {
    color: #000;
}
.Breadcrumb {
    min-height: 44px;
    line-height: 44px;
    border-bottom: 1px solid #eaeaea;
}

.Breadcrumb_noLine {
    border-bottom: none;
}
.Breadcrumb_l {
    display: inline-block;
    margin-right: 5px;
}

.editable-row-operations a {
    margin-right: 8px;
}

.avatar-uploader {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: block;
    background-color: rgba(0, 0, 0, 0.2);
    cursor: pointer;
    z-index: 1;
}
.ant-upload {
    height: 100%;
    width: 100%;
}
.avatar {
    width: 100%;
    height: 100%;
}
.modal_body_0 .ant-modal-body{
    padding: 0
}

.page_banner {
    height: 200px;
    margin: 0 auto;
    background-image: url('../images/index/banner.png');
}

.page_con_r{
    margin-left: 40px;
    padding-bottom: 80px;
    min-height: 680px;
}

.page_con_r .box :global(.ant-table) th,
.page_con_r .box :global(.ant-table) td{
    text-align: center;
}

.page_con_r .box{
    padding: 20px 0 40px 0;
    border: 1px solid #eaeeed;
}

.page_con_r .box .tip{
    padding: 0 20px 20px 20px;
}

.form_item{
    display: inline-block;
    vertical-align: top;
    width: 50%;
}

.login-form{
    height: 222px;
    padding: 0 30px;
}

.login-form .ant-form-item{
    margin-bottom: 2px;
}

.login-form .ant-form-explain{
    display: none;
}