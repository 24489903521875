.roommate {
  position: absolute;
  left: 0;
  top: 0;
  width: 918px;
  padding: 0 16px 40px;
  height: 100%;
  background-color: #fff;
  transition: transform 0.5s;
}
.roommate .top {
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-top: 24px;
  font-size: 16px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  color: rgba(0, 0, 0, 0.85);
}
.roommate .top .hr {
  height: 0;
  flex: 1;
  border-top: 1px solid rgba(0, 0, 0, 0.09);
}
.roommate .content {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 16px;
  margin-top: 16px;
}
.roommate .content .person {
  display: flex;
  position: relative;
  height: 100px;
  padding: 12px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.roommate .content .person img {
  width: 57px;
  height: 76px;
  object-fit: cover;
}
.roommate .content .person .info {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 6px;
  padding: 7px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
}
.roommate .content .person .info .row {
  display: flex;
  justify-content: space-between;
}
.roommate .content .person .info .row .name {
  font-size: 16px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  line-height: 24px;
}
.roommate .content .person .info .row .more {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 24px;
  cursor: pointer;
}
.roommate .content .person .info .bed-number {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
}
.roommate .content .person .info .bed-number span {
  color: #397ef0;
}
.roommate .content .person .self {
  position: absolute;
  bottom: -1px;
  right: -1px;
  width: 48px;
  height: 24px;
  background: #397ef0;
  border-radius: 8px 0px 0px 0px;
  font-size: 12px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  color: #ffffff;
  line-height: 24px;
  text-align: center;
}
.roommate .ant-spin-nested-loading {
  height: calc(100% - 48px);
}
.translateX0 {
  transform: translateX(0);
}
.translateX100 {
  transform: translateX(calc(100% + 1px));
}
.roommate-modal .ant-modal-header {
  padding: 12px 24px;
  background: rgba(0, 0, 0, 0.04);
  border-bottom: none;
}
.roommate-modal .anticon {
  vertical-align: 0.05em;
}
.roommate-modal .ant-modal-title {
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
}
.roommate-modal .info-card {
  display: flex;
  font-size: 14px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  color: rgba(0, 0, 0, 0.65);
  line-height: 22px;
}
.roommate-modal .info-card img {
  width: 102px;
  height: 136px;
  object-fit: cover;
}
.roommate-modal .info-card .info-list {
  margin-left: 24px;
}