.page_buy :global(.ant-table-row:last-of-type){
    background: #fafafa
}

.page_buy :global(.ant-table-row:last-of-type) td:first-of-type span{
    display: none;
}

.page_buy :global(.ant-table-row:last-of-type) td:last-of-type{
    background: #fff;
    text-align: left;
}

.step-input{
    display: inline-block;
    width: 30px;
    outline: none;
    border: none;
    text-align: center;
    background-color: transparent;
    height: 32px;
    line-height: 32px;
    vertical-align: middle;
}

.btn_icon{
    display: inline-block;
    color: #1890ff;
    height: 32px;
    line-height: 32px;
    font-size: 18px;
    vertical-align: middle;
    padding: 0 5px;
}

.txsc {
    width: 140px;
    height: 170px;
    background-color: #f2f2f2;
    margin: 20px;
}

.upload-box {
    position: relative;
    height: 100%;
    width: 100%;
}

.upload-title {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 70px;
    height: 30px;
    margin-top: -15px;
    margin-left: -35px;
    line-height: 30px;
    background-color: rgba(255, 255, 255, 0.8);
    text-align: center;
    color: #0273fd;
    font-size: 12px;
}

.page_buy :global(.avatar) {
    cursor: pointer;
}

