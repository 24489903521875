.repTop{
    display: flex;
    align-items: center;
}
.repTopTitle{
    width: calc(100% - 480px);
    text-align: center;
    /* line-height: 48px; */
    font-size: 32px;
    font-weight: bold;
    color: #000;
    line-height: 34px;
    letter-spacing: 2px;
    padding-top: 15px;
}
.repMain{
    border: 2px solid #a8a4a4;
}

.LcTable{
    font-size: 14px;
}

.bottom_copyright{
    text-align: center;
    font-size: 14px;
    margin-bottom: 10px;
}

.bttom_barcode{
    width: 50%;
    text-align: center;
}

.title{
    margin: 20px 0;
    display: inline-block;
    font-size: 18px;
    color: #333;
    font-weight: bold;
    /* background-image: url('@images/hua.png'); */
    background-repeat: no-repeat;
    background-position: center bottom;
}

.jbxx_icon{
    position: relative;
    width: 140px;
    height: 170px;
    background-color: #f2f2f2;
}

.jbxx_icon_bg{
    position: relative;
    float:left;
    width: 110px;
    height: 130px;
    background-color: #f2f2f2;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #f2f2f2;
}

.jbxx_item{
    display: inline-block;
    width: 33.33%;
    vertical-align: top;
    line-height: 30px;
    padding: 10px 10px 0px 0px;
}
.jbxx_item:nth-of-type(1),
.jbxx_item:nth-of-type(2),
.jbxx_item:nth-of-type(3){
    margin-top: 0
}
.item_label{
    font-size: 13px;
    color: #9a9a9a
}
.item_name{
    font-size: 14px;
    height: 32px;
    line-height: 32px;
    margin-top: 5px;
    color: #000;
    display: 'inline'
}

.item_name_t_clamp_1{
    font-size: 14px;
    line-height: 18px;
    color: #000;
    display: inline;
    left:20px;
}

.kzxx_list{
    border: 1px solid #eaeeed;
}
.kzxx_list + .kzxx_list{
    border-top: none;
}

.kzxx_item{
    box-sizing: border-box;
    display: inline-block;
    width: 50%;
    vertical-align: top;
    height: 30px;
    line-height: 29px;
    border-bottom: 1px solid #eaeeed;
    overflow: hidden;
}
.kzxx_item + .kzxx_item{
    /* border-left: 1px solid #eaeeed; */
}

.kzxx_label{
    display: inline-block;
    vertical-align: top;
    padding: 0 5px;
    width: 140px;
    /* height: 140%; */
    border-right: 1px solid #eaeeed;
    border-left: 1px solid #eaeeed;
    background-color: #f3f4f6;
    color: #000;
    text-align: right;
}

.kzxx_name{
    display: inline-block;
    vertical-align: top;
    padding: 0 5px;
    /* height: 100%; */
    /* width: 300px; */
    color: #8c8c8c;
    overflow: hidden;
}

.kzxx_item_textarea{
    box-sizing: border-box;
    /* display: inline-block; */
    display: flex;
    /* width: 876px; */
    width: 100%;
    vertical-align: top;
    min-height: 30px;
    line-height: 29px;
    /* height: 120px; */
    border-top: 1px solid #eaeeed;
    border-bottom: 1px solid #eaeeed;
}

.kzxx_name_textarea{
    display: inline-block;
    vertical-align: top;
    padding: 0 5px;
    /* height: 100%;
    width: 700px; */
    color: #8c8c8c;
    overflow: hidden;
    text-align: left;
    /* height: 150px; */
    overflow-y: auto;
    margin-left: 3px;
    width: calc(100% - 140px);
}




.ssxx_list{
    border: 1px solid #eaeeed;
}
.ssxx_list + .ssxx_list{
    border-top: none;
}

.ssxx_item{
    box-sizing: border-box;
    width: 50%;
    height: 40px;
    line-height: 40px;
    display: inline-flex;
}
.ssxx_item + .ssxx_item{
    border-left: 1px solid #eaeeed;
}

.ssxx_label{
    padding: 0 10px;
    width: 20%;
    height: 100%;
    border-right: 1px solid #eaeeed;
    background-color: #f3f4f6;
    color: #000;
    text-align: right;
    font-size: 14px;
}

.ssxx_name{
    padding: 0 10px;
    height: 100%;
    width: 80%;
    color: #8c8c8c;
    overflow: hidden;
    font-size: 14px;
}

.bz_item{
    text-align: justify;
    /* margin-top: 8px; */
    margin-top: 5px;
    font-size:14px;
}
.bz_img{
    width: 80px;
    height: 80px;
    margin-left: 60px;
    float:'right';
}

.ml_10 {
    margin-left: 10px;
}

.ml_20 {
    margin-left: 20px;
}

.mt_30 {
    margin-top: 30px;
}

._bg {
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #f2f2f2;
}

.p_sw_big{
    font-size: 14px;
    /* padding: 14px 28px; */
    /* padding: 0px 15px 10px 10px; */
}

.p_sw_big_mt_30{
    padding: 20px 40px;
    margin-top: 30px;
}

.text_title{
    color: #000
}

.jfxx_label{
    box-sizing: border-box;
    display: inline-block;
    width: 25%;
    border: 1px solid #eaeeed;
    vertical-align: top;
    height: 35px;
    font-size: 14px;
    line-height: 35px;
}
.jfxx_label span{
    margin-left:20px;
}

.ejxybd_label1{
    box-sizing: border-box;
    display: inline-block;
    width: 40%;
    border: 1px solid #eaeeed;
    vertical-align: top;
    height: 30px;
    font-size: 14px;
    line-height: 30px;
}
.ejxybd_label2{
    box-sizing: border-box;
    display: inline-block;
    width: 60%;
    border: 1px solid #eaeeed;
    vertical-align: top;
    height: 30px;
    font-size: 14px;
    line-height: 30px;
}
.ejxybd_label1 span,.ejxybd_label2 span{
    margin-left:20px;
}

.singelWarp{
    display: flex;
    margin: 20px 0px;
    padding-left: 10px;
    border-left: 2px solid #05a4ff;
    align-items: center;
    color: #333;
    font-size: 14px;
}
.singelWarp_label{
    width: 10%;
}
.singelWarp_Words{
    width: 90%;
    border: 1px dashed #484848;
    padding: 10px;
    min-height: 40px;
}

.ItemWarp{
    border: 1px solid #eaeeed;
    padding: 15px 10px;
    color: #333;
    font-size: 14px;
}
.itemWarp_label{

}
.itemWarp_Words{
    border: 1px dashed #484848;
    margin: 10px;
    padding: 10px;
    min-height: 35px;
}

.bottom_png{
    width: 64%;
    display: flex;
    justify-content: center;
    /* align-items: center;
    padding: 20px 0px 0px 0px; */
}

.bottom_png .bottom_pngItem{
    width: 100%;
    text-align: center;
    flex: 1;
}
.bottom_png img{
    width: 75%;
}

.jxfz_item{
    box-sizing: border-box;
    width: 25%;
    height: 30px;
    line-height: 30px;
    display: inline-flex;
}
.jxfz_item + .ssxx_item{
    border-left: 1px solid #eaeeed;
}

.jxfz_label{
    padding: 0 10px;
    width: 72%;
    height: 100%;
    border-right: 1px solid #eaeeed;
    background-color: #f3f4f6;
    color: #000;
    text-align: center;
    font-size: 14px;
}

.jxfz_name{
    padding: 0 10px;
    height: 100%;
    width: 60%;
    color: #8c8c8c;
    overflow: hidden;
    font-size: 14px;
}