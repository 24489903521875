.page_buy :global(.ant-table-row:last-of-type){
    background: #fafafa
}

.page_buy :global(.ant-table-row:last-of-type) td:first-of-type span{
    display: none;
}

.page_buy :global(.ant-table-row:last-of-type) td:last-of-type{
    background: #fff;
    text-align: left;
}

.step-input{
    display: inline-block;
    width: 30px;
    outline: none;
    border: none;
    text-align: center;
    background-color: transparent;
    height: 32px;
    line-height: 32px;
    vertical-align: middle;
}

.btn_icon{
    display: inline-block;
    color: #1890ff;
    height: 32px;
    line-height: 32px;
    font-size: 18px;
    vertical-align: middle;
    padding: 0 5px;
}
