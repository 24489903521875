.banner {
    height: 200px;
    margin: 0 auto;
    background-image: url('@images/index/banner.png');
}

.con_r{
    margin-left: 40px;
    padding-bottom: 80px;
    min-height: 680px;
}

.top{
    padding: 20px;
    text-align: center;
}
.title{
    color: #333;
    font-weight: bold; 
    font-size: 20px;
}
.sub{
    font-size: 12px;
    color: #8c8c8c;
    margin-top: 10px;
}
.con{
    border-top: 1px dashed #e8e8e8;
}


