.progress{
    padding: 20px;
}

.progress :global(.ant-progress-text){
    color: #05a4ff
}
.row {
    display: flex;
}
.jbxx_icon{
    position: relative;
    width: 140px;
    height: 170px;
    background-color: #f2f2f2;
}

.upload-box{
    position: relative;
    height: 100%;
    width: 100%;
}

.upload-title{
    position: absolute;
    left: 50%;
    top: 50%;
    width: 70px;
    height: 30px;
    margin-top: -15px;
    margin-left: -35px;
    line-height: 30px;
    background-color: rgba(255, 255, 255, 0.8);
    text-align: center;
    color: #0273fd;
    font-size: 12px;
}

.jbxx_item{
    margin-top: 12px;
    display: inline-block;
    width: 33.33%;
    vertical-align: top;
}
.jbxx_item:nth-of-type(1),
.jbxx_item:nth-of-type(2),
.jbxx_item:nth-of-type(3){
    margin-top: 0
}
.item-label{
    font-size: 12px;
    color: #9a9a9a
}
.item-name{
    height: 32px;
    line-height: 32px;
    margin-top: 2px;
    color: #000;
}

.kzxx_list{
    border: 1px solid #eaeeed;
}
.kzxx_list + .kzxx_list{
    border-top: none;
}

.kzxx_item{
    box-sizing: border-box;
    display: inline-block;
    width: 50%;
    vertical-align: top;
    height: 44px;
    line-height: 44px;
}
.kzxx_item + .kzxx_item{
    border-left: 1px solid #eaeeed;
}

.kzxx_label{
    display: inline-block;
    vertical-align: top;
    padding: 0 5px;
    width: 30%;
    /* height: 140%; */
   /*  border-right: 1px solid #eaeeed;
    background-color: #f3f4f6;
    color: #000; */
    text-align: right;
}

.kzxx_name{
    display: inline-block;
    vertical-align: top;
    padding: 0 5px;
    /* height: 100%; */
    /* width: 300px; */
    color: #8c8c8c;
    overflow: hidden;
}

.kzxx_item_textarea{
    box-sizing: border-box;
    display: inline-block;
    /* width: 876px; */
    width: 100%;
    vertical-align: top;
    min-height: 44px;
    /* line-height: 44px; */
    /* height: 120px; */
}

.kzxx_name_textarea{
    display: inline-block;
    vertical-align: top;
    padding: 0 5px;
    width: 40%;
    /* height: 100%;
    width: 700px; */
    color: #8c8c8c;
    overflow: hidden;
    text-align: left;
    /* height: 150px; */
    overflow-y: auto;
    margin-left: 3px;
}

.btn_add{
    height: 53px;
    line-height: 53px;
    width: 100%;
    text-align: center;
    color: #0273fd;
    background-color: #f6fbff;
    border: 1px dashed #05a3fe;
    font-size: 16px;
    cursor: pointer;
}


.personal div {
    line-height: 22px;
    padding-bottom: 12px;
}

.personal span{
    color: rgba(0,0,0,.85);
    margin-right: 8px;
}
.ueditor img{
    max-width:100%;
}

.ueditor{
    min-height: 400px;
    padding:20px;
}

