.sider_menu {
    width: 220px;
    margin-bottom: 40px;
    flex-shrink: 0;
}

.sider_menu_title,
.sider_menu_item {
    height: 44px;
    line-height: 44px;
    text-align: center;
    cursor: pointer;
}

.sider_menu_title {
    color: #fff;
    background-color: #05a4ff;
}

.sider_menu_item {
    color: #737476;
    background-color: #f3f4f6;
}

.sider_menu_item:hover,
.sider_menu_item.active {
    border-left: 4px solid #05a4ff;
    background-color: #fff;
    color: #05a4ff;
}
