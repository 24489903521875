.viewDormitory .mt-24 {
  margin-top: 24px;
}
.viewDormitory .pdlr-22 {
  padding: 0 22px;
}
.viewDormitory .card {
  border: 1px solid rgba(0, 0, 0, 0.09);
  border-radius: 4px;
  margin-top: 16px;
}
.viewDormitory .card:first-child {
  margin-top: 0;
}
.viewDormitory .card header {
  padding-left: 16px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.04);
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}
.viewDormitory .card header span {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  color: rgba(0, 0, 0, 0.85);
}
.viewDormitory .card .content {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 16px;
  grid-gap: 8px;
}
.viewDormitory .card .content .room {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 7px 16px;
  height: 76px;
  border: 1px solid rgba(0, 0, 0, 0.09);
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  color: rgba(0, 0, 0, 0.25);
  cursor: pointer;
}
.viewDormitory .card .content .room .row {
  display: flex;
  justify-content: space-between;
}
.viewDormitory .card .content .room .row .rest {
  width: 47px;
  height: 24px;
  border-radius: 2px 2px 2px 2px;
  font-size: 12px;
  text-align: center;
  line-height: 24px;
}
.viewDormitory .card .content .can-select .roomname {
  color: #397ef0;
}
.viewDormitory .card .content .can-select .rest {
  background: #e5fff3;
  color: #23d688;
}
.viewDormitory .card .content .can-not-select .roomname {
  color: rgba(0, 0, 0, 0.25);
}
.viewDormitory .card .content .can-not-select .rest {
  background: #fff1f0;
  color: #ff4d4f;
}
.viewDormitory .no-data {
  height: 500px;
  background-image: url(../../images/viewDormitory/nodata.png);
  background-repeat: no-repeat;
  background-position: center 170px;
  background-size: 228px auto;
  text-align: center;
  padding-top: 370px;
  font-size: 16px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.65);
}
.viewDormitory .selected-result .selected-result-tip {
  display: flex;
  align-items: center;
  padding-left: 16px;
  margin: 8px 12px;
  height: 40px;
  background: #fff1f0;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #ffa39e;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  color: rgba(0, 0, 0, 0.65);
  line-height: 40px;
}
.viewDormitory .selected-result .selected-result-tip::before {
  content: 'x';
  display: inline-block;
  margin-right: 7px;
  width: 14px;
  height: 14px;
  background: #f5222d;
  border-radius: 7px;
  text-align: center;
  line-height: 11px;
  color: #fff;
}
.viewDormitory .selected-result .cancel {
  background: #e7f0fd;
  border: 1px solid #84acf5;
}
.viewDormitory .selected-result .cancel::before {
  content: 'i';
  background: #1890ff;
  line-height: 14px;
}
.viewDormitory .selected-result .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px auto 0;
  width: 216px;
  font-size: 16px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
}
.viewDormitory .selected-result .title::before {
  content: '';
  display: inline-block;
  width: 49px;
  height: 2px;
  background-image: url(../../images/viewDormitory/left.png);
  background-position: center;
}
.viewDormitory .selected-result .title::after {
  content: '';
  display: inline-block;
  width: 49px;
  height: 2px;
  background-image: url(../../images/viewDormitory/right.png);
  background-position: center;
}
.viewDormitory .selected-result .selected-result-detail {
  margin: 15px auto 0;
  width: 476px;
}
.viewDormitory .selected-result .selected-result-detail table {
  border: 1px solid rgba(0, 0, 0, 0.09);
  border-collapse: collapse;
}
.viewDormitory .selected-result .selected-result-detail table td {
  padding: 9px 16px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  border: 1px solid rgba(0, 0, 0, 0.09);
  border-collapse: collapse;
}
.viewDormitory .selected-result .selected-result-detail table td:first-child {
  width: 154px;
  height: 40px;
  background: rgba(57, 126, 240, 0.05);
  color: rgba(0, 0, 0, 0.65);
}
.viewDormitory .selected-result .selected-result-detail table td:last-child {
  width: 322px;
  color: rgba(0, 0, 0, 0.85);
}
.viewDormitory .selected-result .btns {
  display: flex;
  justify-content: space-between;
  margin: 15px auto 0;
  width: 208px;
}
.viewDormitory .selected-result .btns .disable {
  color: rgba(0, 0, 0, 0.25);
  background: rgba(0, 0, 0, 0.04);
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.room-detail-modal .ant-modal-header {
  padding: 12px 24px;
  background: rgba(0, 0, 0, 0.04);
  border-bottom: none;
}
.room-detail-modal .ant-modal-footer {
  padding: 15px 16px;
}
.room-detail-modal .anticon {
  vertical-align: 0.05em;
}
.room-detail-modal .ant-modal-title {
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
}