.downloadBtn {
    display: block;
    bottom: 0px;
    position: fixed;
    width: 100%;
    height: 40px;
    text-align: center;
    line-height: 38px;
    border-top:1px solid #1890ff;
    color: #1890ff;
}