.tab_box {
    width: 870px;
}
.tip {
    color: #aeaeae;
}
.tip img {
    height: 20px;
    width: 20px;
    margin-right: 4px;
}
.item {
    margin: 0 18px;
    display: inline-block;
    width: 180px;
    height: 130px;
    border: 1px solid #eaeaea;
    border-radius: 2px;
    margin-bottom: 20px;
}
.item_con {
    height: 100px;
    padding: 10px;
}
.round {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #73bdfe;
    margin-right: 4px;
    display: inline-block;
}
.select_box{
    height: 63px;
}
.select_list{
    display: inline-block;
    text-align: center;
}
.select_item{
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 2px;
    background-image: url('@images/bed_g.jpg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    cursor: pointer;
    vertical-align: top;
}
.select_item_b{
    background-image: url('@images/bed_b.jpg');
}
.select_item_y{
    background-image: url('@images/bed_y.jpg');
}

.item_btn_box{
    padding: 4px 0;
    height: 28px;
    line-height: 20px;
    background-color: #f9fafc;
    border-top: 1px solid #eaeaea;
}
.item_btn{
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    color: #7c7d7f;
    width: 100%;
    text-align: center;
}
.item_btn + .item_btn{
    border-left: 1px solid #eaeaea;
}

.modal_box{
    display: inline-block;
    text-align: center;
}
.modal_list{
    display: inline-block;
    margin: 10px 5px;
    text-align: center;
    cursor: pointer;
}