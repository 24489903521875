.progress{
    padding: 20px;
}

.progress :global(.ant-progress-text){
    color: #05a4ff
}

.jbxx_icon{
    position: relative;
    width: 140px;
    height: 170px;
    background-color: #f2f2f2;
}

.upload-box{
    position: relative;
    height: 100%;
    width: 100%;
}

.upload-title{
    position: absolute;
    left: 50%;
    top: 50%;
    width: 70px;
    height: 30px;
    margin-top: -15px;
    margin-left: -35px;
    line-height: 30px;
    background-color: rgba(255, 255, 255, 0.8);
    text-align: center;
    color: #0273fd;
    font-size: 12px;
}

.jbxx_item{
    display: inline-block;
    width: 50%;
    vertical-align: top;
}

.jbxx_item2{
    display: inline-block;
    width: 33.33%;
    vertical-align: top;
}
.jbxx_item:nth-of-type(1),
.jbxx_item:nth-of-type(2),
.jbxx_item:nth-of-type(3){
    margin-top: 0
}
.item-label{
    font-size: 12px;
    color: #9a9a9a
}
.item-name{
    line-height: 32px;
    color: #000;
}


.kzxx_list{
    border: 1px solid #eaeeed;
}
.kzxx_list + .kzxx_list{
    border-top: none;
}

.kzxx_item{
    box-sizing: border-box;
    display: inline-block;
    width: 50%;
    vertical-align: top;
    min-height: 44px;
    line-height: 43px;
    border-bottom: 1px solid #eaeeed;
}
.kzxx_item + .kzxx_item{
    /* border-left: 1px solid #eaeeed; */
}

.kzxx_label{
    display: inline-block;
    vertical-align: top;
    padding: 0 5px;
    width: 140px; 
    border-right: 1px solid #eaeeed;
    border-left: 1px solid #eaeeed;
    background-color: #f3f4f6;
    color: #000;
    text-align: right; 
    white-space: normal;
}

.kzxx_name{
    display: inline-block;
    vertical-align: top;
    padding: 0 5px;
    /* height: 100%; */
    /* width: 300px; */
    color: #8c8c8c;
    overflow: hidden;
    
}

.kzxx_item_textarea{
    box-sizing: border-box;
    display: flex;
    /* display: inline-block; */
    /* width: 876px; */
    width: 100%;
    vertical-align: top;
    min-height: 44px;
    line-height: 44px;
    /* height: 120px; */
    border-top: 1px solid #eaeeed;
    border-bottom: 1px solid #eaeeed;
}

.kzxx_name_textarea{
    display: inline-block;
    vertical-align: top;
    padding: 0 5px;
    /* height: 100%; */
    /* width: 730px; */
    width: calc(100% - 140px);
    color: #8c8c8c;
    overflow: hidden;
    text-align: left;
    /* height: 150px; */
    overflow-y: auto;
    margin-left: 3px;
}

.btn_add{
    height: 53px;
    line-height: 53px;
    width: 100%;
    text-align: center;
    color: #0273fd;
    background-color: #f6fbff;
    border: 1px dashed #05a3fe;
    font-size: 16px;
    cursor: pointer;
}

.title_top{
    padding-right: 20px; 
}
.title_top_name{
    padding-left: 16px;
    border-left: 4px solid #05a4ff;
    height: 24px;
    font-size: 18px;
    color: #333;
    font-weight: bold;
}
.title_top :global(.ant-btn){
    height: 26px;
    border-radius: 13px;
}
.line{
    margin-left: 10px;
    border-top: 1px solid #eaeeed;
}

/*详细地址 样式调整*/
.detail_adrress :global(.ant-form-item-with-help){
    position: relative;
    top: 20px;
}