.roomDetail .info {
  margin-top: 5px;
  padding: 12px;
  background-color: #e6f7ff;
  border-radius: 4px;
  border: 1px solid #a8deff;
}
.roomDetail .info .row {
  margin-top: 2px;
  font-size: 14px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.65);
  line-height: 22px;
}
.roomDetail .info .row:first-child {
  margin-top: 0;
}
.roomDetail .bed-box-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
}
.roomDetail .bed-box-top .toChose {
  display: flex;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.45);
  line-height: 21px;
}
.roomDetail .bed-box-top .toChose .icon {
  margin-right: 3px;
  width: 20px;
  height: 20px;
  background-image: url(../../images/dormV2/bed01.png);
  background-size: 16px 15px;
  background-repeat: no-repeat;
  background-position: center;
}
.roomDetail .bed-box-top .toChose .icon-active {
  background-image: url(../../images/dormV2/selected-bed-icon.png);
  background-repeat: no-repeat;
}
.roomDetail .bed-box-top .toChose .decription {
  height: 20px;
}
.roomDetail .bed-box-top .IscanChose {
  display: flex;
  justify-content: space-between;
  height: 22px;
  background-color: color;
  width: 193px;
  text-align: center;
}
.roomDetail .bed-box-top .IscanChose .yesLeft {
  display: inline-block;
  height: 22px;
  line-height: 22px;
}
.roomDetail .bed-box-top .IscanChose .yesLeft p {
  text-align: right;
}
.roomDetail .bed-box-top .IscanChose .yesLeft p > span {
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: #01b0f1;
  margin-right: 6px;
}
.roomDetail .bed-box-top .IscanChose .noMiddle {
  display: inline-block;
  height: 22px;
  line-height: 22px;
}
.roomDetail .bed-box-top .IscanChose .noMiddle p {
  text-align: right;
}
.roomDetail .bed-box-top .IscanChose .noMiddle p > span {
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: #e0e0e0;
  margin-right: 6px;
}
.roomDetail .bed-box-top .IscanChose .noRight {
  display: inline-block;
  height: 22px;
  line-height: 22px;
}
.roomDetail .bed-box-top .IscanChose .noRight p {
  text-align: left;
}
.roomDetail .bed-box-top .IscanChose .noRight p > span {
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: #f8ae4f;
  margin-right: 6px;
}
.roomDetail .bed-box-wrapper {
  max-height: 300px;
  overflow: auto;
}
.roomDetail .bed-box-wrapper .bed-box {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 47px;
  grid-row-gap: 30px;
  margin: 30px auto;
  width: 413px;
}
.roomDetail .bed-box-wrapper .bed-box .bed {
  width: 68px;
  height: 95px;
  background-size: 68px auto;
  background-repeat: no-repeat;
  background-position-y: 15px;
  text-align: center;
  padding-top: 85px;
  cursor: pointer;
}