.dormV2-filter {
  position: relative;
  height: 32px;
  overflow: hidden;
  transition: height 0.3s;
}
.dormV2-filter header {
  padding: 0 22px;
  position: relative;
}
.dormV2-filter header .btns {
  position: relative;
  transition: opacity 0.3s;
}
.dormV2-filter header .btnsfolded {
  opacity: 0;
  z-index: -1;
}
.dormV2-filter header .btnsunfolded {
  opacity: 1;
  z-index: 0;
}
.dormV2-filter header .reset {
  margin-left: 8px;
}
.dormV2-filter header .fold {
  position: absolute;
  right: 27px;
  top: 0;
  line-height: 32px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  color: #397ef0;
  cursor: pointer;
}
.dormV2-filter header .fold span {
  display: inline-block;
  height: 8px;
  width: 13px;
  margin-left: 9px;
  background-image: url(../../images/up.png);
  background-size: contain;
}
.dormV2-filter header .fold span.folded {
  transform: rotate(180deg);
}
.dormV2-filter section {
  padding: 0 22px;
  margin-top: 16px;
}
.dormV2-filter section ul {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  color: #000000;
}
.dormV2-filter section ul li {
  display: flex;
  margin-top: 16px;
}
.dormV2-filter section ul li:first-child {
  margin-top: 0;
}
.dormV2-filter section ul li .label {
  white-space: nowrap;
  line-height: 32px;
}
.dormV2-filter section ul li .options {
  display: flex;
  flex-wrap: wrap;
  column-gap: 12px;
  row-gap: 12px;
  color: rgba(0, 0, 0, 0.85);
}
.dormV2-filter section ul li .options .option {
  padding: 5px 16px;
  line-height: 22px;
  color: #000;
  text-align: center;
  background-color: #f6f6f6;
  border-radius: 4px;
  cursor: pointer;
}
.dormV2-filter section ul li .options .selected {
  color: #fff;
  background-color: #84acf5;
}
.dormV2-filter footer {
  padding-left: 22px;
  margin-top: 20px;
  height: 40px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}
.dormV2-filter footer span {
  display: inline-block;
  height: 40px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  color: #397ef0;
  line-height: 40px;
  border-bottom: 2px solid #397ef0;
}
.dormV2-filter .footer-to-top {
  position: absolute;
  height: 32px;
  margin-top: 0;
  top: 0;
  border-bottom: none;
}
.dormV2-filter .footer-to-top span {
  height: 32px;
  line-height: 32px;
}