.title{
    margin: 10px 0;
    padding-bottom: 18px;
    display: inline-block;
    font-size: 22px;
    color: #333;
    font-weight: bold;
    background-image: url('@images/hua.png');
    background-repeat: no-repeat;
    background-position: center bottom;
}

.jbxx_icon{
    position: relative;
    width: 140px;
    height: 170px;
    background-color: #f2f2f2;
}

.jbxx_item{
    margin-top: 12px;
    display: inline-block;
    width: 33.33%;
    vertical-align: top;
}
.jbxx_item:nth-of-type(1),
.jbxx_item:nth-of-type(2),
.jbxx_item:nth-of-type(3){
    margin-top: 0
}
.item-label{
    font-size: 12px;
    color: #9a9a9a
}
.item-name{
    height: 32px;
    line-height: 32px;
    margin-top: 2px;
    color: #000;
}

.ssxx_list{
    border: 1px solid #eaeeed;
}
.ssxx_list + .ssxx_list{
    border-top: none;
}

.ssxx_item{
    box-sizing: border-box;
    display: inline-block;
    width: 50%;
    vertical-align: top;
    height: 44px;
    line-height: 44px;
}
.ssxx_item + .ssxx_item{
    border-left: 1px solid #eaeeed;
}

.ssxx_label{
    display: inline-block;
    vertical-align: top;
    padding: 0 5px;
    width: 140px;
    /* height: 100%; */
    border-right: 1px solid #eaeeed;
    background-color: #f3f4f6;
    color: #000;
    text-align: right;
}

.ssxx_name{
    display: inline-block;
    vertical-align: top;
    padding: 0 5px;
    /* height: 100%; */
    /* width: 300px; */
    color: #8c8c8c;
    overflow: hidden;
}

.bz-item{
    text-align: justify;
    margin-top: 8px;
}
.bz-img{
    width: 80px;
    height: 80px;
    margin-left: 60px;
}